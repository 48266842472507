



























import { Component, Vue } from 'vue-property-decorator';
import { getPathAfterLogin } from '@/router/helpers';
import { AUTH } from '@/services/auth';

@Component
export default class LoginPage extends Vue {
  async signIn(): Promise<void> {
    await AUTH.login()
      .then(() => {
        this.$router.push(getPathAfterLogin()).catch(() => ({}));
        this.$notify({
          type: 'success',
          content: `${this.$t.notify.login.success}${this.getName()}!`,
        });
      })
      .catch((error: string) => {
        console.log(error);
        this.$notify({
          type: 'info',
          content: this.$t.notify.login.error,
        });
      });
  }

  getName() {
    const name = AUTH.azure.getActiveAccount()?.name?.split(' ')[0];
    return name ? `, ${name}` : '';
  }

  mounted() {
    document.addEventListener('keydown', this.keydown);
  }

  beforeDestroy() {
    document.removeEventListener('keydown', this.keydown);
  }

  keydown(e: any) {
    if (e.code === 'Enter') this.signIn();
  }
}
